<div class="card">
  <div class="card-image">
    <figure class="image is-4by3">
      <img src="{{ card.imageUrl }}" alt="" />
    </figure>
  </div>
  <div class="card-content">
    <div class="media-content">
      <p class="title is-4">
        {{ card.title }}
      </p>
      <p class="subtitle is-6">@{{ card.username }}</p>
    </div>
    <div class="content">
      {{ card.content }}
    </div>
  </div>
</div>
